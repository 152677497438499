import React from "react";
import { useTranslation } from "react-i18next";

export const Team = (props) => {
  const { t } = useTranslation();

  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t("Team.teamTitle")}</h2>
        </div>
        <div className="row">
          <div className="card">

            <div className="circle">
              <img src="img/teamDL.PNG" draggable="false" />



              <h4>{t("Team.DL_name")}</h4>


              <h5> {t("Team.DL_rang")}</h5>
            </div>

            <p className="title">{t("Team.DL_descr")}</p>

          </div>

          <div className="card">
            <div className="circle">
              <img src="img/teamRG.jpg" draggable="false" />
              <h4>{t("Team.RG_name")}</h4>
              <h5> {t("Team.RG_rang")}</h5>
            </div>


            <p className="title">{t("Team.RG_descr")}</p>

          </div>
          <div className="card">
            <div className="circle">
              <img src="img/teamAL.jpg" draggable="false" />
              <h4>{t("Team.AL_name")}</h4>
              <h5> {t("Team.AL_rang")} </h5>
            </div>

            <p className="title">{t("Team.AL_descr")}</p>


          </div>
        </div>
        <div className="row">
          <div className="card">
            <div className="circle">
              <img src="img/teamMK.jpg" draggable="false" />
              <h4>{t("Team.MK_name")}</h4>
              <h5> {t("Team.MK_rang")}</h5>
            </div>


            <p className="title">{t("Team.MK_descr")}</p>

          </div>
          <div className="card">
            <div className="circle">
              <img src="img/teamVL.PNG" draggable="false" />
              <h4>{t("Team.VL_name")}</h4>
              <h5> {t("Team.VL_rang")} </h5>
            </div>

            <p className="title">{t("Team.VL_descr")}</p>


          </div>
          <div className="card">
            <div className="circle">
              <img src="img/teamEZ.JPG" draggable="false" />
              <h4 >{t("Team.EZ_name")}</h4>
              <h5> {t("Team.EZ_rang")} </h5>
            </div>

            <p className="title">{t("Team.EZ_descr")}</p>

          </div>
        </div>
        <div className="section-title" style={{ marginBottom: "0px" }}>
          <h2>{t("Team.ourMissionTitle")}</h2>
        </div>
        <div className="col-xs-12 col-md-12" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
          <p>{props.data ? t("Team.ourMissionBody") : "loading..."}</p>
        </div>
      </div>
    </div>
  );
};

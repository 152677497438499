import React from "react";
import { useTranslation } from "react-i18next";
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'

export const SolutionGallery = (props) => {
  const { t } = useTranslation();

  const smallItemStyles = {
    cursor: 'pointer',
    objectFit: 'cover',
    width: '100%',
    maxHeight: '100%',
  }

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t("SolutionGallery.galleryTitle")}</h2>
        </div>
        <div className="row">
          <Gallery withCaption>
            <div
              style={{
                columnCount: 3,
                columnWidth: '300px',
                gapSize: '12'
              }}
            >
              <Item
                alt={t("SolutionGallery.image1_title")}
                caption={t("SolutionGallery.image1_title")}
                original="img/portfolio/hadal_topology.png"
                thumbnail="img/portfolio/hadal_topology.png"
                width='1440'
                height='800'
              >
                {({ ref, open }) => (
                  <img
                    style={smallItemStyles}
                    src="img/portfolio/hadal_topology.png"
                    ref={ref}
                    onClick={open}
                  />
                )}
              </Item>
              <Item
                alt={t("SolutionGallery.image2_title")}
                caption={t("SolutionGallery.image2_title")}
                original="img/portfolio/hadal_paths.png"
                thumbnail="img/portfolio/hadal_paths.png"
                width='1440'
                height='800'
              >
                {({ ref, open }) => (
                  <img
                    style={smallItemStyles}
                    src="img/portfolio/hadal_paths.png"
                    ref={ref}
                    onClick={open}
                  />
                )}
              </Item>
              <Item
                alt={t("SolutionGallery.image3_title")}
                caption={t("SolutionGallery.image3_title")}
                original="img/portfolio/hadal_inventory.png"
                thumbnail="img/portfolio/hadal_inventory.png"
                width='1440'
                height='800'
              >
                {({ ref, open }) => (
                  <img
                    style={smallItemStyles}
                    src="img/portfolio/hadal_inventory.png"
                    ref={ref}
                    onClick={open}
                  />
                )}
              </Item>
              <Item
                alt={t("SolutionGallery.image4_title")}
                caption={t("SolutionGallery.image4_title")}
                original="img/portfolio/hadal_device_details.png"
                thumbnail="img/portfolio/hadal_device_details.png"
                width='1440'
                height='800'
              >
                {({ ref, open }) => (
                  <img
                    style={smallItemStyles}
                    src="img/portfolio/hadal_device_details.png"
                    ref={ref}
                    onClick={open}
                  />
                )}
              </Item>
              <Item
                alt={t("SolutionGallery.image5_title")}
                caption={t("SolutionGallery.image5_title")}
                original="img/portfolio/hadal_vulnerability.png"
                thumbnail="img/portfolio/hadal_vulnerability.png"
                width='1440'
                height='800'
              >
                {({ ref, open }) => (
                  <img
                    style={smallItemStyles}
                    src="img/portfolio/hadal_vulnerability.png"
                    ref={ref}
                    onClick={open}
                  />
                )}
              </Item>
              <Item
                alt={t("SolutionGallery.image6_title")}
                caption={t("SolutionGallery.image6_title")}
                original="img/portfolio/hadal_compliance.png"
                thumbnail="img/portfolio/hadal_compliance.png"
                width='1440'
                height='800'
              >
                {({ ref, open }) => (
                  <img
                    style={smallItemStyles}
                    src="img/portfolio/hadal_compliance.png"
                    ref={ref}
                    onClick={open}
                  />
                )}
              </Item>
            </div>
          </Gallery>
        </div>
      </div>
    </div>
  );
};

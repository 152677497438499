import React from "react";
import { useTranslation } from "react-i18next";

export const Contact = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
              <div className="section-title">
                <h2> { t("Contact.contactTitle") } </h2>
                <p>
                  {t("Contact.contactComment")}
                </p>
              </div>
              </div>
              <div className="col-md-8">
                <button className="btn-demo" onClick={props.openPopup}>{t("Navigation.demo")}</button>
              </div> 
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope"></i> Email
                </span>{" "}
                {props.data ? t("Contact.email") : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fab fa-telegram-plane"></i> &nbsp;&nbsp;Telegram
                </span>{" "}
                <a href={props.data ?  t("Contact.telegram_chat_link"): "/"}>{props.data ? t("Contact.telegram_chat") : "loading"}</a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="section-last">
                <ul>
                  <li>
                    <h2>{t("Contact.welcome_telegram")}:&nbsp;
                      <a href={props.data ? t("Contact.telegram_channel") : "/"}>Hadal Project
                      </a>
                    </h2>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Hadal Project, All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};


import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { News } from "./components/news";
import { PopupForm } from "./components/popup_form";
import { About } from "./components/about";
import { Architecture } from "./components/architecture";
import { Services } from "./components/services";
import {ClientsProposition} from "./components/clients_proposition";
import { SolutionGallery } from "./components/solution_gallery";
import { Conferences } from "./components/conferences";
import { Contact } from "./components/contact";
import { Team } from "./components/team";
import { Teaching} from "./components/teaching";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation openPopup={openPopup}/>
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      {/* <News data={landingPageData.News} /> */}
      <PopupForm isOpen={isPopupOpen} closePopup={closePopup} data={landingPageData.PopupForm} />
      <Services data={landingPageData.Services} />
      <ClientsProposition data={landingPageData.ClientsProposition} />
      <Architecture data={landingPageData.Architecture}/>
      <SolutionGallery data={landingPageData.SolutionGallery} />
      <Team data={landingPageData.Services} />
      <Conferences data={landingPageData.Conferences} />
      {/* <Teaching data={landingPageData.Teaching} /> */}
      <Contact openPopup={openPopup} data={landingPageData.Contact} />
    </div>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { PatternFormat } from "react-number-format";

export const PopupForm = (props) => {
    const { t } = useTranslation(); // добавляем текстовки en/ru
    const { control, register, handleSubmit, formState: { errors }, getValues, reset } = useForm(); // Добавляем getValues
    const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false)
    const [divVisibility, setDivVisibility] = useState(false);


    document.addEventListener("DOMContentLoaded", function () {
        var eventCalllback = function (e) {
            var el = e.target,
                clearVal = el.dataset.phoneClear,
                pattern = el.dataset.phonePattern,
                matrix_def = "+7(___) ___-__-__",
                matrix = pattern ? pattern : matrix_def,
                i = 0,
                def = matrix.replace(/\D/g, ""),
                val = e.target.value.replace(/\D/g, "");
            if (clearVal !== 'false' && e.type === 'blur') {
                if (val.length < matrix.match(/([\_\d])/g).length) {
                    e.target.value = '';
                    return;
                }
            }
            if (def.length >= val.length) val = def;
            e.target.value = matrix.replace(/./g, function (a) {
                return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a
            });
        }
        var phone_inputs = document.querySelectorAll('[data-phone-pattern]');
        for (let elem of phone_inputs) {
            for (let ev of ['input', 'blur', 'focus']) {
                elem.addEventListener(ev, eventCalllback);
            }
        }
    });


    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                props.closePopup();
                setDivVisibility(false);
            }
        };

        if (props.isOpen) {
            setDivVisibility(false); 
            document.addEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [props.isOpen, props.closePopup]);


    if (!props.isOpen) {
        // setDivVisibility(false);
        return null;
    }

    const handleBackgroundClick = (event) => {
        if (event.target === event.currentTarget) {
            props.closePopup();
            setDivVisibility(false);
        }
    };

    // console.log("Ошибка при отправке формы:", error);

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("fields[name_1]", data.firstname + ' ' + data.lastname);
        formData.append("fields[725145_1][536255]", data.phone);
        formData.append("fields[725147_1][536267]", data.mail);
        formData.append("fields[name_3]", data.company);
        formData.append("form_id", '1390850');
        formData.append("hash", 'f05145da28f59fd559c496b008190b16');

        const userOrigin = JSON.stringify({
            datetime: new Date().toString(),
            referer: window.location.href
        });
        formData.append("user_origin", userOrigin);

        try {
            const response = await fetch("https://forms.amocrm.ru/queue/add", {
                method: "POST",
                body: formData,
                encType: "multipart/form-data",
            });

        } catch (error) {
            console.error("Ошибка при отправке формы:", error);
        }
        


        setDivVisibility(true);
        // return false;
        reset();
    };

    // пока captcha не пройдена – нельзя оправить форму
    function onChange(value) {
        setIsCaptchaSuccess(true)
        console.log("captcha value: ", value);
    }

    return (
        <div id="popupform">
            <div class="popup" onClick={handleBackgroundClick}>
                <div class="popup-container">
                    <button class="button-close" onClick={props.closePopup} type="button"></button>
                    <h2 class="popup__heading">{t("PopupForm.title")}</h2>
                    <form class="popup__input-container" name="form" onSubmit={handleSubmit(onSubmit)}>
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="popup__field">
                                    <Controller
                                        name="firstname"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Required" }}
                                        render={({ field }) => (
                                            <input
                                                class="popup__input"
                                                type="text"
                                                placeholder={t("PopupForm.firstname")}
                                                required
                                                {...field}
                                            />
                                        )}
                                    />
                                </label>
                            </div>
                            <div class="col-sm-6">
                                <label class="popup__field">
                                    <Controller
                                        name="lastname"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Required" }}
                                        render={({ field }) => (
                                            <input
                                                class="popup__input"
                                                type="text"
                                                placeholder={t("PopupForm.lastname")}
                                                required
                                                {...field}
                                            />
                                        )}
                                    />
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <label class="popup__field">
                                    <Controller
                                        name="company"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Required" }}
                                        render={({ field }) => (
                                            <input
                                                class="popup__input"
                                                type="text"
                                                placeholder={t("PopupForm.company")}
                                                required
                                                {...field}
                                            />
                                        )}
                                    />
                                </label>
                            </div>
                            <div class="col-sm-6">
                            <label class="popup__field">
                                <Controller
                                    name="mail"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Required" }}
                                    render={({ field }) => (
                                        <input
                                            class="popup__input"
                                            type="email"
                                            placeholder={t("PopupForm.mail")}
                                            required
                                            {...field}
                                        />
                                    )}
                                />
                            </label>
                            </div>
                        </div>
                        <div class="row">
                        <div class="col-sm-6">
                            <label class="tel popup__field">
                                <Controller
                                    name="phone"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Required" }}
                                    render={({ field }) => (
                                        <PatternFormat
                                            className="popup__input"
                                            mask={t("PopupForm.mask")}
                                            format={t("PopupForm.phone_format")}
                                            value=""
                                            placeholder={t("PopupForm.phone")}
                                            {...field}
                                        />
                                    )}
                                />
                            </label>
                        </div>
                        </div>
                        <div class="popup__captcha">
                            <ReCAPTCHA
                                sitekey="6LeGjSoqAAAAALy8DiIhwThw5ZuqyVTUGu-ZrCQU"
                                onChange={onChange}
                                theme="light"
                                size="normal"
                            />
                        </div>
                        <button class="button popup__btn" disabled={!isCaptchaSuccessful} type='submit'>{t("PopupForm.submit")}</button>
                    </form>
                    {divVisibility && 
                    <div class="popup__approved">
                        {t("PopupForm.form-approved")}
                    </div>
                    }
                    <span class="popup__attention">
                        {t("PopupForm.form-ok1")}
                    </span>
                </div>
            </div>
        </div>
    );
};

import React from "react";
import { useTranslation } from "react-i18next";

export const Conferences = (props) => {
  const { t } = useTranslation();
  return (
    <div id="conferences">
      <div className="container">
        <div className="section-title text-center">
          <h2> <span style={{ color: "rgba(107, 199, 183, 1)" }}>Hadal</span> {t("Conferences.conferencesTitle")}</h2>
        </div>
        <div className="row" style={
          {
            display: "flex",
            height: "20vw",
          }
        }>
          <iframe title="nexthop_dzen"
            // width="480" 
            // height="270" 
            src="https://dzen.ru/embed/vv82sBK-o6mk?from_block=partner&from=zen&mute=0&autoplay=0&tv=0"
            allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media"
            frameborder="0"
            scrolling="no"
            allowfullscreen
            style={
              {
                position: "relative",
                height: "20vw",
                width: "70%",
                margin: "auto",
              }
            }
          >
          </iframe>
        </div>
        <div className="row" style={
          {
            marginTop: "5vh",
            display: "flex",
            height: "20vw",
          }
        }>

          <iframe
            // width="720"
            // height="405"
            src="https://rutube.ru/play/embed/c4399827ce7dbc8c1a9dabc1fe26fd2e"
            frameBorder="0"
            allow="clipboard-write; autoplay"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
            style={
              {
                position: "relative",
                height: "20vw",
                width: "70%",
                margin: "auto",
              }
            }
          >
          </iframe>


        </div>
      </div>
    </div>
  );
};
